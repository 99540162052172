<template>
  <portal to="history-event-modal">
    <div
      v-if="event"
      id="wrap-history-event-modal"
      class="wrap-history-event-modal"
    >
      <div class="header">
        <TooltipElement
          :content="$t('history_event_back_tooltip') /*關閉(Esc)*/"
          placement="top"
          alwaysShow
        >
          <div class="esc" @click="goBack()">
            <img src="@/assets/icons/clear.svg" alt="" />
          </div>
        </TooltipElement>
        <TooltipElement
          :content="$t('history_event_screen_capture') /*擷取畫面*/"
          placement="top"
          alwaysShow
        >
          <div class="btn-action" @click="handleScreenCapture">
            <img src="@/assets/icons/print-screen.svg" alt="" />
          </div>
        </TooltipElement>
        <TooltipElement
          v-if="hasVideo"
          :content="$t('video_download' /*下載影片*/)"
          placement="right"
          alwaysShow
        >
          <div v-if="searchLoading" class="btn-action">
            <RingLoading
              :width="32"
              :height="32"
              :ringWidth="7"
              background="transparent"
            />
          </div>
          <div
            v-else
            class="btn-action"
            :class="{ disabled: total !== videoList.length || total === 0 }"
            @click="updateIsShowVideoDownloadPanel(true)"
          >
            <img src="@/assets/icons/download.svg" alt="" />
          </div>
        </TooltipElement>
        <div class="divider"></div>

        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :content="$t('history_event_previous_tooltip') /*上一筆(左鍵)*/"
          placement="bottom"
        >
          <div
            class="switch"
            :class="{ disabled: isStopPrev }"
            @click="setPrevEvent"
          >
            <img src="@/assets/icons/arrow-prev.svg" alt="" />
          </div>
        </el-tooltip>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :content="$t('history_event_next_tooltip') /*下一筆(右鍵)*/"
          placement="bottom"
        >
          <div
            :class="{ disabled: isStopNext }"
            class="switch"
            @click="setNextEvent"
          >
            <img src="@/assets/icons/arrow-next.svg" alt="" />
          </div>
        </el-tooltip>
        <div class="event-index">{{ eventIndex }}</div>
      </div>
      <div class="container-history-modal">
        <EventDetailFr v-if="historyEventModalType === 'fr'" :event="event" />
        <EventDetailLpr v-else :event="event" :type="eventType" />
        <div class="event-action">
          <div class="top">
            <div v-if="showMapLock" class="action lock" @click="handleMapLock">
              <img
                v-if="isMapDraggable"
                src="@/assets/icons/WebMapCenter.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/WebMapCenter_Locked.svg" alt="" />
            </div>
          </div>
          <div class="bottom">
            <div class="action" @click="handleEngineerMode">
              <img
                :class="{ 'engineer-disabled': !isEngineerMode }"
                src="@/assets/icons/Engineer.svg"
                alt=""
              />
            </div>
            <EventMisjudgment v-if="showMisjudgement" :event="event" />
          </div>
        </div>
      </div>
      <div
        v-if="isPrintScreen"
        id="history-screen-shot"
        class="history-screen-shot"
      >
        <EventDetailFr
          v-if="historyEventModalType === 'fr'"
          :event="event"
          :isPrintScreen="isPrintScreen"
        />
        <EventDetailLpr
          v-else
          :event="event"
          :type="eventType"
          :isPrintScreen="isPrintScreen"
        />
      </div>

      <div v-if="isPrintScreen" class="is-capturing">
        <RingLoading />
      </div>

      <VideoDownload
        v-if="isShowVideoDownloadPanel"
        :queryByTime="false"
        :canDelete="false"
        @close="updateIsShowVideoDownloadPanel(false)"
      />
    </div>
  </portal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import html2canvas from 'html2canvas'
import { getEventFilename } from '@/utils/lib.js'
import { apiEditBoviaFrEvent } from '@/api/'

export default {
  name: 'HistoryEventModal',
  components: {
    VideoDownload: () => import('@/components/Base/VideoDownload.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    EventMisjudgment: () => import('@/components/Base/EventMisjudgment.vue'),
    EventDetailLpr: () => import('@/components/Base/EventDetailLpr.vue'),
    EventDetailFr: () => import('@/components/Base/EventDetailFr.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue')
  },
  data() {
    return {
      // isMapLock: false,
      isEngineerMode: false,
      isPrintScreen: false,
      eventSettings: {
        lpr: {
          eventIndex: 'historyEventIndex',
          eventList: 'historyEventList',
          pageSize: 'historyPageSize',
          currentPage: 'historyCurrentPage',
          searchMethod: this.searchEvents,
          updateEventIndexMethod: this.updateHistoryEventIndex,
          updateFocusIndexMethod: this.updateFocusEventIndex
        },
        fr: {
          eventIndex: 'frCurrentIndex',
          eventList: 'frEventList',
          pageSize: 'frPageSize',
          currentPage: 'frCurrentPage',
          searchMethod: this.searchFrEvents,
          updateEventIndexMethod: this.updateFrCurrentIndex,
          updateFocusIndexMethod: this.updateFrFocusIndex
        },
        urgent: {
          eventIndex: 'urgCurrentIndex',
          eventList: 'urgEventList',
          pageSize: 'urgPageSize',
          currentPage: 'urgCurrentPage',
          searchMethod: this.searchUrgentEvents,
          updateEventIndexMethod: this.updateUrgCurrentIndex,
          updateFocusIndexMethod: this.updateUrgFocusIndex
        },
        video: {
          eventIndex: 'videoIndex',
          eventList: 'historyVideoList',
          pageSize: 'pageSize',
          currentPage: 'videoCurrentPage',
          searchMethod: this.searchVideoList,
          updateEventIndexMethod: this.updateVideoIndex,
          updateFocusIndexMethod: this.updateVideoFocusIndex
        }
      }
    }
  },
  computed: {
    ...mapState(['codeBooks', 'tagList']),
    ...mapState('history', [
      'searchMode',
      'historyEventList',
      'historyCurrentPage',
      'historyEventIndex',
      'historyEventModalType'
    ]),
    ...mapState('historyFr', [
      'frEventList',
      'frCurrentPage',
      'frCurrentIndex'
    ]),
    ...mapState('historyUrgent', [
      'urgMode',
      'urgEventList',
      'urgPageSize',
      'urgCurrentPage',
      'urgCurrentIndex'
    ]),
    ...mapState('historyVideo', [
      'videoCurrentPage',
      'videoIndex',
      'historyVideoList',
      'pageSize'
    ]),
    ...mapState('video', [
      'total',
      'videoList',
      'searchLoading',
      'isShowVideoDownloadPanel',
      'isMapDraggable'
    ]),
    ...mapGetters(['getEventTitleId', 'timezone']),
    ...mapGetters('history', ['historyModalEvent', 'totalEventNo']),
    ...mapGetters('historyFr', ['frModalEvent', 'totalFrEventNo']),
    ...mapGetters('historyUrgent', ['urgModalEvent', 'totalUrgEventNo']),
    ...mapGetters('historyVideo', ['modalVideo', 'totalVideoNo']),
    // ...mapGetters(['pageCount']),
    event() {
      const eventMap = {
        lpr: this.historyModalEvent,
        fr: this.frModalEvent,
        urgent: this.urgModalEvent,
        video: this.modalVideo
      }
      return eventMap[this.historyEventModalType]
    },
    eventIndex() {
      const indexMap = {
        lpr: this.totalEventNo,
        fr: this.totalFrEventNo,
        urgent: this.totalUrgEventNo,
        video: this.totalVideoNo
      }
      return indexMap[this.historyEventModalType]
    },
    eventType() {
      // lpr, chased, sos, video
      if (this.historyEventModalType === 'video') return 'video'
      if (this.historyEventModalType === 'urgent') return this.urgMode
      return 'lpr'
    },
    hasVideo() {
      return (
        this.historyEventModalType === 'urgent' ||
        this.historyEventModalType === 'video' ||
        (this.historyEventModalType === 'lpr' &&
          this.historyModalEvent.chasingStartTime)
      )
    },
    isStopPrev() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (!settings) return true // 如果事件不是 'lpr', 'fr', 或 'urgnet', 'video'，回傳 true
      return this[settings.eventIndex] === 0 && this[settings.currentPage] === 1
    },
    isStopNext() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (!settings) return true // 如果事件不是 'lpr', 'fr', 或 'urgnet', 'video'，回傳 true
      return this[settings.eventIndex] === this[settings.eventList].length - 1
    },
    showMapLock() {
      return (
        this.historyEventModalType === 'lpr' ||
        this.historyEventModalType === 'urgent' ||
        this.historyEventModalType === 'video'
      )
    },
    showMisjudgement() {
      return this.historyEventModalType === 'lpr'
    }
  },
  watch: {
    event() {
      if (!this.event) this.updateHistoryEventModalShow(false)
    },
    historyCurrentPage(newVal, oldVal) {
      let eventIdx = newVal < oldVal ? this.historyEventList.length - 1 : 0
      this.updateHistoryEventIndex(eventIdx)
      this.updateFocusEventIndex(eventIdx)
    },
    frCurrentPage(newVal, oldVal) {
      let eventIdx = newVal < oldVal ? this.frEventList.length - 1 : 0
      this.updateFrCurrentIndex(eventIdx)
      this.updateFrFocusIndex(eventIdx)
    }
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    ...mapMutations('history', [
      'updateHistoryEventIndex',
      'updateFocusEventIndex',
      'updateHistoryEventModalShow'
    ]),
    ...mapMutations('historyFr', [
      'updateFrCurrentIndex',
      'updateFrFocusIndex'
    ]),
    ...mapMutations('historyUrgent', [
      'updateUrgCurrentIndex',
      'updateUrgFocusIndex'
    ]),
    ...mapMutations('historyVideo', [
      'updateVideoIndex',
      'updateVideoFocusIndex'
    ]),
    ...mapMutations('video', [
      'updateIsShowVideoDownloadPanel',
      'updateIsMapDraggable'
    ]),
    ...mapActions('history', ['searchEvents']),
    ...mapActions('historyFr', ['searchFrEvents']),
    ...mapActions('historyUrgent', ['searchUrgentEvents']),
    ...mapActions('historyVideo', ['searchVideoList']),
    apiEditBoviaFrEvent,
    goBack() {
      this.updateHistoryEventModalShow(false) // 關閉跳窗
    },
    setPrevEvent() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (this.isStopPrev) return
      if (this[settings.eventIndex] === 0 && this[settings.currentPage] > 1) {
        settings.searchMethod({ page: this[settings.currentPage] - 1 })
      } else {
        let eventIdx = this[settings.eventIndex] - 1
        settings.updateEventIndexMethod(eventIdx)
        settings.updateFocusIndexMethod(eventIdx)
      }
    },
    setNextEvent() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (this[settings.eventIndex] === this[settings.eventList].length - 1) {
        if (this[settings.eventList].length === this[settings.pageSize]) {
          settings.searchMethod({ page: this[settings.currentPage] + 1 })
        }
      } else {
        let eventIdx = this[settings.eventIndex] + 1
        settings.updateEventIndexMethod(eventIdx)
        settings.updateFocusIndexMethod(eventIdx)
      }
    },
    onKeyUp(e) {
      e.preventDefault()
      if (e.keyCode === 27) this.goBack() // Esc
      if (e.keyCode === 37) this.setPrevEvent() // Left
      if (e.keyCode === 39) this.setNextEvent() // Right
    },
    /* 取得畫面截圖放於剪貼簿 */
    getScreenShot() {
      let src = document.getElementById('wrap-history-event-modal')
      html2canvas(src, { useCORS: true, allowTaint: true }).then(function (
        canvas
      ) {
        canvas.toBlob(function (blob) {
          navigator.clipboard
            .write([
              new window.ClipboardItem(
                Object.defineProperty({}, blob.type, {
                  value: blob,
                  enumerable: true
                })
              )
            ])
            .then(function () {})
        })
      })
    },
    handleScreenCapture() {
      this.isPrintScreen = true
      setTimeout(() => {
        this.screenCapture()
      }, 1000)
    },
    screenCapture() {
      let src = document.getElementById('history-screen-shot')
      html2canvas(src, { useCORS: true, allowTaint: true })
        .then((canvas) => {
          canvas.toBlob((blob) => {
            const link = document.createElement('a')
            const imgURL = URL.createObjectURL(blob)
            link.download = getEventFilename(this.event)
            link.href = imgURL
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(imgURL)
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isPrintScreen = false
        })
    },
    handleMapLock() {
      this.updateIsMapDraggable(!this.isMapDraggable)
    },
    handleEngineerMode() {
      this.isEngineerMode = !this.isEngineerMode
    }
  }
}
</script>

<style>
.el-tooltip {
  font-size: 18px;
  font-weight: 300;
}
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-history-event-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: px2rem(24) 0 px2rem(24) px2rem(36);
  box-sizing: border-box;
  background: #282942;
  z-index: 10;
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: px2rem(16);
}

.esc {
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }
}

.btn-action {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }
  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.divider {
  width: 1px;
  height: 90%;
  background: #ffffff80;
}

.switch {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.event-index {
  min-width: 54px;
  font-size: px2rem(18);
  line-height: 24px;
  color: #282942;
  background: #ffe99f;
  border-radius: 13px;
  margin-right: 24px;
  padding: 0 6px 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-history-modal {
  display: flex;
  height: calc(100% - 80px);
  background: #282942;
}

.history-screen-shot {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  background: #282942;
  padding: 20px;
  box-sizing: border-box;
}

.is-capturing {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.event-action {
  padding: 0 12px;
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }

  .action {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.lock {
      background: #ffffff33;
      &:hover {
        background: #ffffff80;
      }
    }

    img {
      width: 22px;
      height: 22px;
      @include filter_FFF;
    }
    .engineer-disabled {
      @include filter_FFF_50;
    }
  }
}

@media screen and (max-width: 1440px) {
  // .wrap-history-event-modal {
  //   padding: 12px 0px 12px 24px;
  // }
}

@media screen and (max-width: 840px) {
  .wrap-history-event-modal {
    padding: 8px;
  }
}
</style>